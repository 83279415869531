.myDraft-box {
    background-color: white;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 20px;
}

.modal-confirm-data p {

    font-size: 13px;
    color: grey;

}

.modal-text {
    text-align: center;
    font-size: 17px;
    color: black;
    word-wrap: break-word;
}

.draft-icon {
    display: flex;
    justify-content: right;
}

.draft-schdule-image {
    width: 60px;
    height: 60px;
    border-radius: 15px;
}

.thread-ul {
    list-style-type: none;
    position: relative;
    left: 20px;
    border-left: 2px solid gold;
    padding: 10px 30px 30px 30px;
    margin-top: 10px;
}

.thread-preview-image {
    /* height: 70px;
    width: 70px; */
    height: auto;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.text-container-draft {
    word-wrap: break-word;
}

.image-myqueue-draft {
    display: flex;
    flex-wrap: wrap;
}
@media only screen and (max-width: 900px) and (min-width: 0px) {
    .nav-hear-bar {
        display: block !important;
    }
}

/* @media only screen and (max-width: 1301px) and (min-width: 401px) {
    .nav-hear-bar {
        display: none;
    }
} */

.nav-hear-bar {
    display: none;
}
.iq-main-menu {
    /* background-color: #002348 !important; */
    background-color: #284E79 !important;
    height: 100% !important;

}

.nav-link {
    color: #FFFFFF;
}



.nav-link:hover {
    color: white !important;
}


.sidebar-default:not(.left-bordered).sidebar-soft .navbar-nav .nav-item .nav-link:not(.disabled).active,
.sidebar-default:not(.left-bordered).sidebar-soft .navbar-nav .nav-item .nav-link:not(.disabled)[aria-expanded=true] {
    background-color: white !important;
    box-shadow: unset;
    color: #007AFF !important;
}

.sidebar-nav-layout {
    display: flex;
}



.sidebar.navs-rounded-all .sidebar-body .nav-item .nav-link {
    border-radius: 0 !important;
}


.sidebar-toggle {
    display: none;
}

@media only screen and (max-width: 900px) and (min-width: 0px) {
    .right-sidebar-toggle {
        display: block !important;
    }
}

@media only screen and (max-width: 2900px) and (min-width: 901px) {
    .right-sidebar-toggle {
        display: none !important;
    }
}

/* .right-sidebar-toggle {
    display: none !important;
} */

.composeButton {
    /* background-color: #FF8400; */
    background-color: white;
    border: none;
    border-radius: 20px;
    padding: 15px 10px 15px 10px;
    width: 100%;
    color: #284E79;
    font-weight: 700;
    font-size: 15px;
}

/* .footer-nav {
    background-color: rgba(217, 217, 217, 0.06);

    position: fixed;
    width: -webkit-fill-available;
    bottom: -273px;

} */


.Ellipse-icon {
    width: 45px;
    border-radius: 50px;
}

.emoji-mar {
    margin-right: 5px;
}

.emoji-mar-bolt {
    position: relative;
    left: -20px
}

.emoji-mar-arrow {
    position: relative;
    left: -18px
}

.footer-ul {
    list-style-type: none;
    position: relative;
    left: -21px;
    top: -2px;
}

.footer-ul li {
    padding-top: 0px;
}

.logout-icon {
    position: relative;
    right: 9px;
    font-size: 20px;
    top: 4px;
}

.nav-link0 {
    display: flex;
    padding: 15px 0px 15px 10px;
    justify-content: space-between;
}

.footer-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

@media only screen and (max-width: 900px) and (min-width: 0px) {
    .footer-nav {
        position: absolute;
        bottom: 45px;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

.footer-list {
    padding: 8px;
    background-color: rgba(217, 217, 217, 0.06);
}

.popover-header-vertical {
    border: none;
    background-color: white;
}

.vertical-popover-header {

    display: flex;
    border: 1px solid grey;
    border-radius: 7px;
    overflow-x: hidden;
    padding: 10px 14px 10px 10px;
    background-color: #f1f1f1;
    margin-bottom: 10px;
}

.vertical-popover-header-2 {
    display: flex;
    border: 1px solid grey;
    border-radius: 7px;
    overflow-x: hidden;
    padding: 10px 14px 10px 10px;
    margin-bottom: 10px;

}

.popover-footer-vert {
    /* border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 1px solid grey;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    background-color: #284E79; */
    color: #284E79;
    cursor: pointer;
}



.fotter-sidebar {
    /* background-color: #284E79;
    ;
    color: white; */
    background-color: white;
    color: #284E79;
    border: none;
    font-size: 17px;
}

.validation-signin {
    color: red;
}

.popover-notiii {
    background-color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px
}


/* ----------RIGHT SIDE BAR CSS STARTS--------- */




.right-sidebar-panel .media-height {
    overflow-y: auto;
}

.compose-h4 {
    padding-left: 20px;
    margin-top: 30px;
}

.card-form-compose {
    border-radius: 10px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    ; */
    padding-left: 5px;
    padding-right: 29px;
    margin-left: 6px;
    margin-right: 10px;
}

.card-form-compose h5 {
    padding-top: 30px;
    padding-bottom: 30px;

}

.text-area-compose {
    background-color: #ebebeb;
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 10px 0px 0px 10px;
    height: 200px;
}

.button-rightsidebar {
    background-color: #284E79;
    border: none;
    border-radius: 5px;
    width: 100%;
    color: white;
    padding-top: 4px;
    padding-bottom: 4px;
}



.image-select-background {
    /* background-color: #ececec; */
    /* background-color: white;
    border-radius: 20px;
    padding: 20px 10px 20px 10px; */
    margin-bottom: 10px;
}

.cross-remove {
    position: relative;
    bottom: 37px;
    left: 2px
}

.cross-remove-2 {
    position: relative;
    bottom: 10px;
    left: 2px;
    color: red;
}

.input-attachment {
    float: right;
}

.image-icon {
    margin-right: 5px
}

.attachments-flex {
    display: flex;
    justify-content: space-between;
}

.button-unsaved {
    background-color: white;
    padding: 5px 10px 5px 10px;
    border: none;
    color: lightgray;
}

.button-saved {
    background-color: white;
    padding: 5px 10px 5px 10px;
    border: none;
    color: rgb(24, 20, 20);
}

.button-queue {
    display: flex;
}

.button-rightsidebar-drop {
    background-color: #284E79;
    border: none;
    border-radius: 5px;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 4px;
    padding: 4px;
    margin-left: 3px;
}

.dropdown-queue {
    background-color: #284E79;
    color: white;
    list-style-type: none;
    padding: 10px 10px 10px 10px;
    text-align: center;
    margin-top: 5px;
    border-radius: 4px;
    cursor: pointer;

}

.text-area-red {
    color: red;
}





.picker-container {
    position: relative;
    width: 300px;
}

.emoji-icon {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 5px;
}

.input-style {
    padding: 7px 30px 7px 10px;
    width: calc(100% - 40px);
    border-radius: 3px;
    border: 1px solid #999;
    margin-bottom: 10px;
}


.reactGiphySearchbox-componentWrapper {
    width: 100% !important;
}

.save-draft {
    float: right;
    margin-bottom: 1px;
    cursor: pointer;

}

.display-button {
    display: flex;
    justify-content: space-between;
}

.upgrade-button {
    background-color: #4893e2;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 15px;
}

.Edit-queen-button {
    background-color: #007AFF;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 15px;
    margin-right: 20px;
}

.popover-rigthbar {
    background-color: rgb(14, 134, 255);
    color: rgb(255, 255, 255);
    padding: 10px 5px 10px 5px;
}

.para-alert {
    width: 95%;
}

.new-account {

    width: 100%;
    border-radius: 4px;
    border: 2px solid #747577;
    font-size: 17px;
}




/* ----------SCHEDULE CARD CSS  STARTS ---------- */


.schdeule-card {

    border: 1px solid rgb(210, 208, 208);
}

.schdeule-card-header {
    display: flex;
}

.schdeule-card-text {
    margin-left: 20px;
}

.schdeule-img-fluid {
    border-radius: 30px;
}

.schdeule-card-text span {
    font-size: 20px;
    font-weight: 500;
}

.schdeule-card-text p {
    font-weight: 200;
}

.modal-schedule-text {
    color: black;
}

.date-time {}

.custom-input {
    border: 1px solid rgb(171, 166, 166);
    padding: 10px 20px;
    border-radius: 10px;
    width: 95%;
    cursor: pointer;
    text-align: center;
}

.react-datepicker__header {
    text-align: center;
    background-color: #284E79 !important;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3em;
    padding: 8px 0;
    position: relative;

}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: white !important;
    font-weight: bold;
    font-size: 1.44em;

}

.react-datepicker__time-container {
    width: 10rem;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
    width: 10rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 153px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3em;
    /* height: 188px; */
    height: auto;
}

.react-datepicker__triangle {
    position: absolute !important;
    left: -28px !important;
    transform: translate3d(160px, 0px, 0px) !important;

}

.react-datepicker__day-name {
    color: white !important;
    display: inline-block;
    width: 1.7em;
    line-height: 1.7em;
    text-align: center;
    margin: 0.166em;
    padding-top: 0px;
    font-size: 15px;
}

.react-datepicker__month {
    margin: 0.4em;
    text-align: center;
    font-size: 15px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 167px;
    top: 15px
}

.react-datepicker__navigation--previous {
    top: 9px;
    left: 2px;
}

.addnewaccountbutton {
    background-color: #284E79;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 15px;
}


/* ----------SCHEDULE CARD CSS  ENDS ---------- */




/* --------------EDIT QUEUE CSS STARTS----------- */

.day-input {
    appearance: textfield;
    /* Remove the toggle arrow */
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    outline: none;
}

.day-input::-webkit-inner-spin-button,
.day-input::-webkit-outer-spin-button {
    appearance: none;
    /* Remove the spinner buttons in Webkit browsers */
    margin: 0;
    /* Remove the default margin */
}


.form-month-box {
    background-color: #EFEFEF;
    border-radius: 30px;

}

.form-group-queue {
    background-color: #EFEFEF;
    padding: 20px 15px 20px 15px;
    border-radius: 30px;
}



.month-form-select {
    /* display: block;
    width: 100%; */
    /* padding: 0.375rem 2.25rem 0.375rem 0.75rem; */
    /* padding: 0px;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.8;
    color: black;
    background-color: #EFEFEF;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: none;
    border-radius: 0.313rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; */
    /* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e); */

}

.form-date-box {
    background-color: #EFEFEF;
    padding: 7px 15px 7px 15px;
    border-radius: 10px;

}

.form-date-box label {
    color: black;
    font-weight: 200;

}

.downArrow {
    color: black;
    position: relative;
    top: 7px
}

.date-form-select {
    background-color: #EFEFEF;
    border: none;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.8;
    color: black;
    border-radius: 0.313rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    width: 100%;
}

.display-date {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    padding-right: 0px;
}

.calender-icon {
    color: black;
    font-size: 20px;
}

.Date-calender {
    color: black;
    font-weight: 800;
    padding-left: 10px;
}

.button-edit-queue {}

.edit-queue {
    background-color: #284E79;
    padding: 10px 20px;
    color: white;
    border: none;
    margin-top: 20px;
    border-radius: 10px;
}

.queue-text {
    font-size: 10px;
    position: relative;
    top: -3px
}

/* .react-datepicker__time-list {
    background-color: #f2f2f2;

}

.react-datepicker__time-list-item {
    color: #333333;
}


.react-datepicker__time-list-item--selected {
    background-color: #ffcc00;
    color: #ffffff;
} */


.converted-time {
    display: flex;
    background-color: #007AFF;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 10px;
    justify-content: space-between;

}

.button-queue {
    border: none;
    border-radius: 20px;
    background-color: white;
    color: #007AFF;
    /* padding: 1px 10px 2px 11px; */

}

.date-picker-time {
    display: flex;
    justify-content: flex-start;
}

.timeSlot-Button--1 {
    background-color: #284E79;
    color: white;
    font-size: 24px;
    font-weight: 700;
    border-radius: 36px;
    border: none;
    padding: 0px 17px 1px 17px;
    /* position: relative; */
    /* left: -53px */
}

.table-time-clumn {
    display: flex;
    justify-content: space-between;

}

.buttoncross {
    /* background-color: #007AFF;
    color: white;
    padding: 0px 6px 0px 6px;
    border-radius: 30px; */
    color: #284E79;
    /* border: 2px solid grey; */
    padding: 0px 9px 0px 9px;
    border-radius: 13px;
    font-weight: 600;
    cursor: pointer;

}

.checkbox-field {
    cursor: pointer;
    color: #284E79;
}

.Edit-noresponse-text {
    color: #284E79;
    font-weight: 600;
    margin-top: 30px;
}


/* --------------EDIT QUEUE CSS ENDS----------- */














/* ----------RIGHT SIDE BAR CSS ENDS--------- */

.word-count {
    display: flex;
    justify-content: right;
    color: #4893e2;


}

.AI-options-screen {
    /* padding-left: 0px; */
}

.two-column-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two equal-width columns */
    grid-gap: 10px;
    /* Adjust the gap between columns as needed */
    list-style: none;
    /* Remove default list styles */
}

.two-column-list li {
    display: flex;
    align-items: center;
    margin: 5px 0;
    /* Adjust the vertical spacing between list items as needed */
}

.two-column-list li svg {
    width: 24px;
    /* Adjust the width to match the size of your icons */
    margin-right: 10px;
    /* Adjust the spacing between the icon and text as needed */
}

/* If you're using font-awesome icons as inline SVGs, you can target them like this: */
.two-column-list li .svg-inline--fa {
    width: 24px;
    margin-right: 10px;
}

.icon-bule {
    color: #284E79;

}

.logo-icon {
    width: 58px;
}

.icon-text {
    cursor: pointer;
}

.popover-body-verticalnav button {
    background-color: white;
    border: 2px solid dodgerblue;
    border-radius: 50px;
    color: dodgerblue;
    font-weight: bold;
    padding: 0px 6px;
}

.popover-body-verticalnav span {
    font-size: 14px;
    padding-left: 5px;
}

.schduled-button-latter {
    background-color: #284E79;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;

}

.datetime-picker-thread {

    display: flex;

}

.custom-input-thread {
    border: 1px solid rgb(171, 166, 166);
    padding: 10px 20px;
    border-radius: 10px;
    width: 94%;
    cursor: pointer;
    text-align: center;
}

.thread-schedule {
    background-color: #284E79;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 15px;

}

.form-value-sche label {
    color: #284E79;
    font-weight: 800;
    padding-bottom: 7px;
}

.footer-muttliaccount-modal {
    display: flex;
    justify-content: space-between;
}

.modal-footer {
    display: block !important;
}

.cancel-modal {
    background-color: white;
    border: 2px solid #284E79;
    border-radius: 5px;
    color: #284E79;
    font-weight: 600;
    padding: 10px 20px;
}

.connect-modal {
    background-color: #284E79;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    padding: 10px 20px;
}



/* Schdule later thread CSS */

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 116px !important;
    top: 15px;
}

/* .react-datepicker-popper {

    transform: translate3d(11.25px, -266.875px, 0px) !important;
} */

.react-datepicker__time-container {
    width: 7rem !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 110px !important;

}

.react-datepicker {
    position: relative !important;
    left: -4px !important;
}

.schd-button {
    background-color: #284E79;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 0px 15px;
}









/* ----------------DARK THEME------------------------ */

.dark-buttton {
    background-color: red;
    border: none;
    border-radius: 5px;
    width: 100%;
    color: #284E79;
    padding-top: 4px;
    padding-bottom: 4px;
}

/* .right-sidebar-mini-dark {
    background-color: rgb(71, 70, 70) !important;

} */
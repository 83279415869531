/* .span-thread {
    color: black;
    margin-left: 20px !important;
}

.span-icon {
    width: 100px;
}

.thread-ul {
    list-style-type: none;
    position: relative;
    left: 28px;
    border-left: 2px solid gold;
    padding: 10px 30px 30px 30px;
    margin-top: 10px;
}

.thread-li {
    
}

.image-img {
    width: 60px;
    height: 60px;
    border-radius: 15px;
}

.image-gif {
    width: 60px;
    height: 60px;
    border-radius: 15px;
}

.Post-Thread {
    background-color: #007AFF;
    border: none;
    padding: 10px 20px 10px 20px;
    color: white;
    border-radius: 15px;
}

.display-buttns {
    display: flex;
    justify-content: space-between;
}

.post-delete {
    position: relative;
    top: 15px;
    font-size: 20px;
    cursor: pointer;
    color: rgb(221, 30, 30);
}

.post-delete:hover {
    color: rgb(221, 30, 30);
} */




.image-icon {
    cursor: pointer;
}

.gif-icon {
    cursor: pointer;
}

.svg-inline--fa {
    cursor: pointer;
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

.thread-image-container-ui {
    display: flex;
    justify-content: space-between;
}

.Post-Thread {
    background-color: #284E79;
    border: none;
    padding: 10px 20px 10px 20px;
    color: white;
    border-radius: 5px;
}

.display-buttns {
    display: flex;
    justify-content: space-between;
}

.post-delete {
    position: relative;
    top: 15px;
    font-size: 20px;
    cursor: pointer;
    color: rgb(221, 30, 30);
}

.span-thread {
    color: black;
    margin-left: 20px !important;
}

.span-icon {
    width: 50px !important;
    height: 50px !important;

    border-radius: 50%;
}

.thread-ul {
    list-style-type: none;
    position: relative;
    left: 20px;
    border-left: 2px solid gold;
    padding: 10px 30px 30px 30px;
    margin-top: 10px;
}

.thread-li {
    /* display: table-caption; */
    word-wrap: break-word;
}

.image-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.image-gif {
    width: 60px;
    height: 60px;
    border-radius: 15px;
}

.thread-save-draft {
    float: right;
    margin-bottom: 20px;
    cursor: pointer;
}

.cross-remove-thread-icon {
    position: relative;
    top: -12px;
    color: rgb(236, 236, 236);
    margin-left: 2px;
}

.cross-remove-thread-icon-2 {
    position: relative;
    bottom: 29px;
    left: 2px;
    color: red;
    margin-left: 2px;
}

.cross-remove-thread-icon-image {
    position: relative;
    bottom: 29px;
    left: 2px;
    color: red;
    margin-left: 2px;
}

.cross-remove-thread-icon-gif {
    position: relative;
    top: -2px;
    color: rgb(236, 236, 236);
    margin-left: 2px;
    color: red;
}

.DailyIns-Card-footer-right-icon-thread {
    position: relative;
    top: 13px;
    color: dodgerblue;
}

.text-red {
    color: red;
}

.icon-text-black {
    color: black;
    cursor: pointer;
}
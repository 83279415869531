.analytics-box {
    border: 1px solid rgb(201, 196, 196);
    background-color: white;
    border-radius: 10px;
    padding: 10px 5px 10px 10px;
}

/* .search-tweet-ai {
    display: flex;
    justify-content: space-between;
} */

.graph-title {
    margin-top: 50px;
}

.graph-box {
    background-color: rgb(255, 255, 255);
    border: 1px solid #284E79;
    border-radius: 10px;
}

.Table-analytics {
    margin-top: 80px;
    margin-bottom: 15px;
}

.table-analytics-box {
    border: 1px solid #284E79;
    border-radius: 10px;
    padding: 20px 0px;
}

.table-header {
    border-bottom: 1px solid rgb(173, 214, 255);


}

.table-header th {
    font-weight: 800;
    font-size: 13px;
}

.table-display {
    display: flex;
    justify-content: space-between;
}


button.selected {
    /* Style for selected buttons */
    background-color: blue;
    /* Change to the desired color */
    color: white;
    /* Change to the desired text color */
}


/* CustomDatePicker.css */

/* Style for the container div holding the date picker */
.date-picker-container {
    position: relative;
}

/* Style for the button */
.date-picker-button {
    padding: 8px 16px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    outline: none;
}

/* CustomDatePicker.css */

/* Style for the calendar icon */
.calendar-icon {
    cursor: pointer;
    margin-left: 10px;
}

/* Style for the date picker dropdown */
.date-picker-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
}

.button-fillter {
    display: flex;
    margin-bottom: 20px;


}

.button-days {
    background-color: white;
    border-top: 1px solid #284E79;
    border-left: 1px solid #284E79;
    border-bottom: 1px solid #284E79;
    border-right: 1px solid #284E79;
    /* border-right: none; */
    padding: 8px 15px;


}

.selected-filter {
    background-color: #284E79;
    border-top: 1px solid #284E79;
    border-left: 1px solid #284E79;
    border-bottom: 1px solid #284E79;
    border-right: none;
    padding: 8px 15px;
    color: white;
}

.date-filter {
    padding: 8px 15px;
    /* width: 20% !important; */
    border: 1px solid #284E79;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.selected-date {
    background-color: #284E79;
    color: white;
}

.export-button {
    background-color: #284E79;
    color: white;
    border: none;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 10px;

}

.export-no-button {
    background-color: rgb(200, 200, 200);
    color: white;
    border: none;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 10px;
}

.table-display-flex {
    display: flex;
    justify-content: space-between;
}

.table-display-flex h5 {
    font-size: 20px;
}

.table-display-flex-2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.table-display-flex-2 h5 {
    font-size: 20px;
}

.table-form {
    background-color: white !important;
    border: 1px solid #284E79 !important;
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 14px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker {

    position: relative !important;
    left: -53px !important;
}

.most-search-box {
    margin-top: 50px;
}

.most-search-box h4 {
    font-size: 20px;
}

.popver-link-button {
    background-color: #284E79;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.text-container-ana {
    width: 5px;
    /* Set the width to the desired value */
    overflow: hidden;
    /* Hide the overflow content */
    white-space: nowrap;
    /* Prevent text from wrapping */
    text-overflow: ellipsis;
    /* Display ellipsis (...) for overflow */
    /* border: 1px solid #ccc; */
    /* Optional border for the container */
    padding: 5px;
    /* Optional padding for better appearance */
}




@media only screen and (max-width: 900px) and (min-width: 0px) {
    .analytics-box h5 {
        font-size: 13px;
    }

    .analytics-box {
        margin-bottom: 15px;
    }

    .table-display-flex {
        display: block;
    }

    .button-fillter {
        margin-top: 15px;
    }




}

.text-tabke {
    /* width: 100px !important; */
    /* 
    overflow: hidden;

    white-space: nowrap;

    text-overflow: ellipsis;

    padding: 5px;
    background-color: aqua; */




    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */




    /* word-break: break-all; */
}














/* 
.card-carousel {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.card-list {
    display: flex;
    transition: all 0.3s ease-in-out;
}

.card {
    flex: 0 0 200px;
 
    margin: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card img {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 10px;
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
} */
.title-cart {
    float: right;
    margin-top: 40px;
    margin-right: 40px;
}

.product-title {
    font-size: 25px;
    font-weight: 900;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif */
    font-family: 'Roboto', Tahoma, Geneva, Verdana, sans-serif
}

.product-amount.selected {
    font-size: 16px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    transition: ease(0.01);
}


.product-amount {
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.image-payment-form {
    width: 54px;
    height: 47px;
}

.image-payment-form-2 {
    margin-right: 9px;
    width: 50px;
    height: 46px;

}


.title-cart h3 {
    color: white;
}

.icon-title {
    display: flex;
}

.icon-title h3 {
    margin-top: 5px;
}

.title-cart p {
    color: gray;
    font-size: 15px;
}

.title-cart h2 {
    color: white;
    font-weight: 800;

}

.para-topprio {
    width: 500px;
}

.display-subtitle-flex {
    display: flex;
    justify-content: space-between;
}

.display-subcart-text {
    display: flex;
    justify-content: space-between;
}

.display-subcart-text span {
    color: white;
}

.display-text-cart h5 {
    color: white;

}

.display-text-cart p {
    color: gray;

}

.breakout-1 {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid white; */
    margin-bottom: 10px;

}

.breakout-1 p {
    color: white;
}

.breakout-2 {
    display: flex;
    justify-content: space-between;
}

.breakout-2 button {
    background-color: gray;
    color: white;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    border: none;
}

.breakout-3 {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid white;
    margin-top: 15px;
}

.breakout-3 p {
    color: white;
    margin-top: 10px;
}

.breakout-4 {
    display: flex;
    justify-content: space-between;


}

.breakout-4 p {
    color: white;

}








.form-payment-strpe {
    /* margin-left: 40px; */
    margin-top: 40px;
    padding: 0px 100px 0px 40px;
}

.input-form-paymet {
    border-radius: 5px;
    /* border: 1px solid gray; */
    padding: 10px 0px 10px 10px;
    width: 80%;

}

.card-form-paymet {
    border-radius: 5px;

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02) !important;
    border: 1px solid rgb(226, 226, 226) !important;
    padding: 10px 0px 10px 10px;
    width: 78.5% !important;
}

.input-form-paymet:focus {
    border: 2px solid rgb(127, 191, 255);
}

/* .card-form-paymet {
    border-radius: 5px;
    border: 1px solid rgb(226, 226, 226) !important;
    padding: 10px 0px 10px 10px;
    width: 70%;
} */

.card-form-paymet:focus {
    border: 2px solid rgb(127, 191, 255);
}

.card-info {
    width: 70%;
}

.expiry-date {
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
    padding: 10px 0px 10px 10px;
}

.expiry-date:focus {
    border: 2px solid rgb(127, 191, 255);
}

.Cvv {
    width: 100%;
    border-radius: 5px;
    border: 1px solid gray;
    padding: 10px 0px 10px 10px;
}

.Cvv:focus {
    border: 2px solid rgb(127, 191, 255);
}

.region-form {
    width: 70% !important;
}

/* .form-select {
    width: 70% !important;
} */

.button-card-form {
    background-color: #284E79;
    border-radius: 10px;
    border: none;
    width: 80%;
    padding: 10px 0px;
    color: white;
    margin-bottom: 50px;
    margin-top: 20px;

}

.terms {
    width: 70%;
}

.breakout-line {
    position: relative;
    height: 300px;
    /* Set the desired height for the container div */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.breakout-line p {
    position: absolute;
    bottom: 0;
    /* left: 50%;
    transform: translateX(-50%); */


    border: none;
    border-radius: 10px;
    margin-top: 75px;
    padding: 10px 0px;
    color: white;

}



@media only screen and (max-width: 900px) and (min-width: 0px) {
    .title-cart {
        margin-top: 40px;
        float: none;
        margin-right: 0px;
        padding: 0px 15px;
    }

    .para-topprio {
        width: 100%;
    }

    .display-subcart-text span {
        color: white;
        font-size: 13px;
    }

    .display-text-cart h5 {
        color: white;
        font-size: 14px;

    }

    .breakout-line {
        position: relative;
        height: 200px;
        /* Set the desired height for the container div */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .breakout-line p {
        position: absolute;
        bottom: 0;
        /* left: 50%;
        transform: translateX(-50%); */


        border: none;
        border-radius: 10px;
        margin-top: 75px;
        padding: 10px 0px;
        color: white;

    }


    .form-payment-strpe {
        /* margin-left: 40px; */
        margin-top: 40px;
        padding: 0px 40px 0px 40px;
    }

    .input-form-paymet {

        width: 100%;
    }

    .card-form-paymet {

        width: 97% !important;
    }

    .card-form-paymet {

        width: 100%;
    }

    .card-info {
        width: 100%;
    }

    .button-card-form {
        width: 100%;
    }

    .form-select {
        width: 100% !important;
    }

    .terms {
        width: 100%;
    }

}



/* ------------------------------------MY PAYMENTS CSS STARTS---------------------------- */
/* body {
    background-color: white;
} */

.white-screen {
    height: 63rem !important;
}

.payment-screen-body {
    background-color: white !important;
    width: cover;
    /* height: 100%; */
    padding-bottom: 25px;
}

.payment-header-box {
    padding: 70px 0px 20px 0px;
}

.payment-menu-bar {

    border: 2px solid #284E79;
    border-radius: 15px;
    padding: 10px 10px 10px 20px;
    width: 100%;
    height: 100%;
}

.payment-menu-bar h3 {
    color: black;
    font-weight: 400;
}

.payment-menu-listing {

    margin-top: 35px;
}

.payment-menu-listing span {
    font-size: 17px;

}

.payment-menu-listing ul {
    position: relative;
    right: 30px;
    margin-top: 10px;
}

.payment-menu-listing li {
    list-style-type: none;
    font-size: 16px;
    margin-bottom: 10px;

}



.payment-menu-bar.selected {
    background-color: #284E79;
    color: white;
}


.payment-button.selected {
    background-color: white;
    color: #284E79;
}

.product-amoount.selected {

    color: white;
}

.payment-back {
    /* background-color: #007afc;
    color: white;
    padding: 14px 26px;
    border-radius: 50px;
    border: none;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 800; */



    position: relative;
    bottom: -10px;
    border-radius: 50px;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    text-align: center;
    cursor: pointer;
    font-size: 25px;
    padding: 14px 26px;
    margin-bottom: 20px;



}

.font-arrow-back {
    position: relative;
    top: 14px;
    font-size: 20px;
    cursor: pointer;
}

/* 
.container-payment {
    position: relative;
    height: 300px;
   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #007afc;
    width: 100%;
    border: none;
    border-radius: 10px;
    margin-top: 15px;
    padding: 10px 0px;
    color: white;
    font-weight: 600;
} */


.payment-button {

    background-color: #284E79;
    width: 100%;
    border: none;
    border-radius: 10px;
    margin-top: 15px;
    padding: 10px 0px;
    color: white;
    font-weight: 600;
}

.bottom-button {}

.payment-no-response {
    text-align: center;
}

.payment-no-response h4 {
    border: 1px solid dodgerblue;
    padding: 20px 0px;
    box-shadow: inset 0 -3em -1em rgb(255, 255, 255), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    box-shadow: inset 0 -5em -6em rgb(255, 255, 255), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    background-color: #d2e8ff;
}

/* ------------------------------------MY PAYMENTS CSS ENDS---------------------------- */
.title-text-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

}

.title-text-center h1 {
    color: white;
    font-size: 60px;
}
/* --------USER INTERSET STYLE CSS STARTS----------- */


.button-container {
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem;
    margin-top: 10px;

}

.button-container>* {
    flex: 0 0 calc(25% - 0.5rem);
    /* Display 4 buttons per line (25% width each) with margin */
    margin: 0.25rem;
    /* Add margin between the buttons */
}

.next-btn {
    border: none;
    background-color: #284E79;
    padding: 5px 35px 5px 35px;
    border-radius: 10px;
    color: white;
    font-size: 17px;
}

.user-button {
    padding: 10px 0px;
    /* margin: 5px; */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    background-color: #284E79 !important;
}

.cross-interest {
    background-color: white;
    border-radius: 50px;
    margin-left: 20px;
    color: black;
    padding: 2px 4px 2px 4px;
    margin-top: 2px;
    position: relative;
    top: 2px;
}

.display-data {
    /* font-size: 12px; */
    color: white;
    margin: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    padding-left: 30px;
    background-color: #284E79 !important;
    border: none !important;
}


.countriesList-container {
    background-color: aqua;
    padding-top: 20px;
    margin-bottom: 50px;
}





body {
    background-color: #284E79;
}

.user-interset-body {}

.user-interset-form-body {
    padding: 5% 0%;
    background-color: white;
    min-height: 100vh;
    /* display: flex;
    justify-content: center;
    background-color: yellow;

    margin: 0% 12%;
    height: 100%; */

}

.form-heading h4 {
    font-size: 40px;
    color: #284E79;
    font-weight: 700;
}

.form-heading p {
    font-size: 20px;
    color: black;
    font-weight: 600;
}

.form-user-interset {
    margin: 0% 18%;
    margin-top: 5%;
}

.form-display-data-user-interset {
    background-color: white;
    height: auto;
    margin-bottom: 30px;
    margin-top: 30px;
}

.suggestion-user-interset h5 {
    font-weight: 800;
    font-family: 'Roboto';
    color: black;
}

.select-interest {
    width: 100%;
    padding: 11px 10px;
    border-radius: 10px;
    background-color: #eeeeee;
    border: none;
}





@media only screen and (max-width: 900px) and (min-width: 0px) {
    .form-heading h4 {
        font-size: 26px;
        color: #FF8400;
        font-weight: 700;
    }

    .form-heading p {
        font-size: 16px;
        color: black;
        font-weight: 600;
    }

    .form-user-interset {
        margin: 0% 12%;
        margin-top: 5%;
    }

    .form-user-interset h5 {
        font-size: 10px;
        margin-bottom: 10px;
    }

    .suggestion-user-interset h5 {
        font-size: 13px;
        font-weight: 600;
    }

    .user-button {
        padding: 3px 40px;


    }

    .button-container>* {

        flex-basis: calc(100% - 0.5rem);
        /* margin: 1.25rem; */
        padding: 20px 0px;
    }

    .button-container {
        display: flex;
        flex-wrap: wrap;
        margin: -0.25rem;
        margin-top: 10px;

    }



    .next-btn {
        border: none;
        background-color: #284E79;
        padding: 5px 35px 5px 35px;
        border-radius: 10px;
        color: white;
        font-size: 12px;
    }
}




/* --------USER INTERSET STYLE CSS END----------- */
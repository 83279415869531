.sign-in-detail {
  color: $white;
  background-color: #284E79;
  //  padding: 10.25rem 5.25rem 1.25rem 5.25rem;
  // height: 100vh;
  // color: $white;
  // flex-direction: column;
  // display: flex;
  // align-items: center;
  // // justify-content: center;
  // justify-content: space-between;
  // font-family: "Roboto";
  // font-style: normal;
  // font-weight: 400;
  // font-size: 18px;
  // line-height: 30px;
  color: white;
  font-size: 17px;
  padding: 8rem 0px 0px 0px;
}

.authtext {
  color: $white;
  background-color: #284E79;
  text-align: center;
  color: white;
  font-size: 17px;
  // padding: 3rem 0px 0px 0px;
  position: relative;
  top: 2rem;
  padding-bottom: 5px;
}

// .authtext {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   margin: 0;
//   padding: 0;
//   list-style-type: none;
//   color: white
// }

.sign-in-page {
  height: 100vh;
  // background: var(--bs-primary);
  // background: rgba(var(--bs-primary-rgb),.8);
  // background: -moz-linear-gradient(-45deg, rgba(var(--bs-primary-rgb),.8) 0%, var(--bs-primary) 100%);
  // background: -webkit-linear-gradient(-45deg, rgba(var(--bs-primary-rgb),.8) 0%, var(--bs-primary) 100%);
  // background: linear-gradient(135deg, rgba(var(--bs-primary-rgb),.8) 0%, var(--bs-primary) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(var(--bs-primary-rgb),.8)', endColorstr='var(--bs-primary)', GradientType=1);
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  margin: 0rem auto;
  padding: 0rem auto;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.sign-in-logo {
  display: inline-block;
  width: 100%;

  img {
    width: 109.01px;
    height: 108.92px;
    // left: 421px;
    //top: 374px;
  }
}

.sign-up-logo {
  display: inline-block;
  width: 100%;

  img {
    width: 180px;
    height: 34px;
    float: right;
    // left: 421px;
    //top: 374px;
  }
}

.sign-in-from {
  padding: 0 3.75rem;
  width: 100%;
  height: 100%;
  // background-color: antiquewhite;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.sign-info {
  border-top: 1px solid $border-color;
  margin-top: 1.875rem;
  padding-top: 1.25rem;
}

.iq-social-media {
  margin: 0;
  padding: 0;
  float: right;

  li {
    list-style: none;
    float: left;
    margin-right: 0.625rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      height: 1.875rem;
      width: 1.875rem;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.875rem;
      display: inline-block;
      background: rgba(var(--bs-primary-rgb), 0.2);
      color: var(--bs-primary) !important;

      @if $enable-rounded ==true {
        @include radius-mixin($border-radius);
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#container-inside {
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0px auto;
  padding: 0px auto;
  overflow: visible;
}

#circle-small {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 0s;
  position: absolute;
  top: 12.5rem;
  left: -9.375rem;
  background: $white;
  width: 18.75rem;
  height: 18.75rem;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }

  opacity: 0.4;
}

#circle-medium {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 0.3s;
  position: absolute;
  top: 3.125rem;
  left: -18.75rem;
  background: $white;
  width: 37.5rem;
  height: 37.5rem;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }

  opacity: 0.3;
}

#circle-large {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 0.6s;
  position: absolute;
  top: -6.25rem;
  left: -28.125rem;
  background: $white;
  width: 56.25rem;
  height: 56.25rem;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }

  opacity: 0.2;
}

#circle-xlarge {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 0.9s;
  position: absolute;
  top: -15.625rem;
  left: -37.5rem;
  background: $white;
  width: 75rem;
  height: 75rem;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }

  opacity: 0.1;
}

#circle-xxlarge {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 1.2s;
  position: absolute;
  top: -25rem;
  left: -46.875rem;
  background: $white;
  width: 93.75rem;
  height: 93.75rem;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }

  opacity: 0.05;
}

@include media-breakpoint-down(lg) {
  .sign-in-page {
    overflow-y: scroll;
  }

  .sign-in-detail {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@include media-breakpoint-down(md) {
  .sign-in-detail {
    padding: 3.125rem 1.25rem;
  }
}

@-webkit-keyframes circle-small-scale {
  0% {
    -webkit-transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes circle-small-scale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.btn-primary {
  background-color: #007bff !important;
  border-color: #007bff !important;
  align-items: center;
  align-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 21.0583px;
  line-height: 30px;
  color: #ffffff;
}

.btn-primary {
  background-color: #007bff !important;
  border-color: #007bff !important;
  align-items: center;
  align-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 21.0583px;
  line-height: 30px;
  color: #ffffff;

  // display: block;
  // justify-content: end;
}

// .btn-primary:hover{
// 	cursor: pointer;
// 	background-color: #007BFF !important;
// 	border-color: #007BFF !important;
// 	transform: scale(1.0);
// 	font-style: normal;
// font-weight: 400;
// font-size: 21.0583px;
// line-height: 30px;
// color: #FFFFFF;
// 	}

.authtext {
  font-family: "Roboto";
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  // position: absolute;

  /* identical to box height */
}

.apptext {
  font-size: 16px;
}

.atext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}

/// user interest scss

.interest-page {
  height: 100vh;
  background-color: #002348;
  // background: var(--bs-primary);
  // background: rgba(var(--bs-primary-rgb),.8);
  // background: -moz-linear-gradient(-45deg, rgba(var(--bs-primary-rgb),.8) 0%, var(--bs-primary) 100%);
  // background: -webkit-linear-gradient(-45deg, rgba(var(--bs-primary-rgb),.8) 0%, var(--bs-primary) 100%);
  // background: linear-gradient(135deg, rgba(var(--bs-primary-rgb),.8) 0%, var(--bs-primary) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(var(--bs-primary-rgb),.8)', endColorstr='var(--bs-primary)', GradientType=1);
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  margin: 0rem auto;
  padding: 0rem auto;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.no-gutters {
  height: 100%;
}

.tweetheading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 41px;
  color: #284E79;
}

.tweetheading2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;

  color: #000000;
}

.selectT {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
}

.interestd {
  width: 70%;
  margin-top: 5%;
  text-align: left;
}

.selectd {
  background-color: red;
}

.user-in-from {
  padding: 0 3.75rem;
  width: 100%;
  height: 100%;
  // background-color: antiquewhite;
  text-align: center;
  // display: flex;
  // flex-flow: column;
  justify-content: center;
  align-items: center;
}

.biod {
  width: 70%;
  text-align: left;
}

.form-control {
  background-color: #efefef;
}

.headdiv {
  width: 90%;
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 5%;
  justify-content: center;
  margin-top: 10%;
  margin-left: 5%;
  text-align: center;
  margin-right: 5%;
}

.middiv {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  // margin-top: 5%;
  justify-content: center;
}

.bottomdiv {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 40px;
  justify-content: center;
}



.btn-block-next {
  // margin-right:2% ;
  width: 15%;
  text-align: center;
  margin-right: 2%;
  background-color: #284E79 !important;

}

.btn-block-back {
  // margin-right:2% ;
  width: 15%;
  text-align: center;
  margin-right: 2%;
  color: #284E79 !important;
  border: 1px solid #284E79;
  background-color: white !important;

}

.buttonrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// New user interest

.interest-main-container {
  background-color: #002348;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .interest-form-container {
    width: 50%;
    height: 100%;
    background-color: #fff;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 8rem 0;
  }

  .interest-form-top-subcontainer {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    .form-heading {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #ff8400;
      text-align: center;
    }

    .form-subHeading {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      margin-top: 2%;
    }

    .dropdown-container {
      width: 70%;
    }
  }

  .interest-form-bottom-subcontainer {
    width: 70%;
    // display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.countriesList-container {
  width: 35vw;
  //   height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  //align-items: center;
  //   margin: 8px;
  color: #ffffff;
  //   background-color: #007bff;
  // overflow: auto;
  height: auto;
}

.country-container {
  width: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ff8400;
  font-size: 75%;
  border-radius: 30px;
}

.country-bottom-container {
  width: auto;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ff8400;
  font-size: 75%;
  border-radius: 5px;
  cursor: pointer;
}

.btn-warning {
  color: #ffffff;
  background-color: #ff8400 !important;
  border-color: #ff8400 !important;
  // align-items: center;
  // align-content: center;
  // font-family: "Roboto";
  // font-style: normal;
  // font-weight: 400;
  // font-size: 21.0583px;
  // line-height: 30px;
}

.btn-success {
  color: #ffffff;
  background-color: #002348 !important;
  border-color: #002348 !important;
}

.form-desHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
}

.nextbtn {
  width: 100%;
  margin-top: 10%;
}

.button-login {
  background-color: #284E79 !important;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
}
$dark: #60658a;
$primary: #50b5ff !default;
$navbar-vertical-dark-bg: $dark !default;
$navbar-vertical-color-bg: var(--#{$variable-prefix}primary) !default;

$navbar-vertical-width: 16.625rem !default;
$navbar-vertical-mini-width: 5rem !default;

$navbar-vertical-active-shadow: 0 10px 20px -10px rgba(darken($primary, 25%), .4);
$navbar-vertical-hover-shadow: null;

$navbar-vertical-shadow-enable: true;
$navbar-horizontal-shadow-enable: true;

$navbar-vertical-shadow: 0 0px 30px 0 rgba(darken($primary, 25%), .05);
$navbar-horizontal-shadow: 0 0px 30px 0 rgba(darken($primary, 25%), .05);

$navbar-vertical-transition-duration: 400ms;
$navbar-vertical-transition: all 400ms ease;
$navbar-vertical-transition-duration: 400ms;
$navbar-vertical-transition-function-ease: ease;
$navbar-vertical-transition-function-ease-in-out: ease-in-out;
.queue-schedule-list {
    background-color: white;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
}

.image-myqueue {
    /* display: flex;
    justify-content: center; */
}

.queue-schdule-image {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    margin-left: 10px;
    border-radius: 10px;
}

.image-myqueue-modal {
    display: flex;
    justify-content: center;
}



.image-myqueue {
    display: flex;
    flex-wrap: wrap;
}

.image-myqueue>* {
    flex: 0 0 calc(25% - 0.5rem);
    /* Display 4 buttons per line (25% width each) with margin */
    margin: 0.25rem;
    /* Add margin between the buttons */
}


.image-container {
    flex-basis: 50%;
    max-width: 50%;
    box-sizing: border-box;
    padding: 5px;
}


.form-queue {}

.queue-form {
    width: 100%;
    padding: 10px 5px;
    border: 1px solid #cecece;
    border-radius: 10px;
    margin-bottom: 10px;
}

.queue-button {
    background-color: dodgerblue;
    /* width: 100%; */
    border: none;
    color: white;
    padding: 5px 30px;
    font-size: 15px;
    border-radius: 10px;

}

.queue-schedule-image {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    border-radius: 10px;
}

.schedule-text p {
    color: black;
    font-size: 15px;
}

.schedule-text button {
    background-color: #284E79;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;

}
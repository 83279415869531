/* -----------MY QUEUE CSS STARTS----------------- */
.alert-text {
    color: black;
    font-weight: 700;

}

.alert-icon {
    margin-right: 5px;
    color: #197fec;
}

.alert-icon-1 {
    margin-right: 5px;

}

.span-alert {
    border-bottom: 1px solid black;
    font-weight: 400;
    cursor: pointer;
}

.myqueue-card {
    border: 1px solid rgb(200, 197, 197) !important;
    border-radius: 20px;
    margin: 10px;
}

.myqueue-card-header {
    display: flex;
    justify-content: space-between;
}

.myqueue-card-image {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    position: relative;
    top: -6px
}

.myqueue-img-fluid {
    border-radius: 50px;
    width: 40px;
    height: 40px;
}

.myqueue-Card-footer {
    border-top: 1px solid rgb(225, 225, 225);
    padding: 0 !important;
}

.footer-card-list {
    list-style-type: none;
    position: relative;
    right: 40px;
    margin-top: 10px;
}

.footer-card-list li {
    display: inline-block;
    margin-left: 20px;

}

.card-title {
    font-weight: 200;
    color: black;

}

.MyQueue-2nd-sections {
    list-style-type: none;
}

.MyQueue-2nd-sections li {
    display: inline-block;

}



.tabs-myqueue {
    background-color: #f8f8f8 !important;
    border: none !important;
    display: flex;
}



.nav-tabs .nav-link {
    color: black !important;
    font-weight: 900;
}

.nav-tabs .nav-link.active {
    color: #284E79 !important;
    background-color: #f8f8f8 !important;
    border-color: #f8f8f8 !important;
}

.nav-tabs .nav-link:hover {
    color: #284E79 !important;
    background-color: #f8f8f8 !important;
    border-color: #f8f8f8 !important;
}

.icon-not {
    /* background-color: #7FBDFF;
    padding: 10px;
    border-radius: 50px; */
}

.schdule-meeting {
    padding: 30px;
    background-color: #f8f8f8;
    border-radius: 10px;
}


.product-carousel-container {
    position: relative;
    left: 38px;
    width: 90%;
    /* background-color: rgb(216, 216, 216); */
    border-radius: 13px;
}

.carousel-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    z-index: 1;
}

/* Styling for navigation arrows */
.splide-arrow {
    background-color: white;
    color: white;
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    outline: none;
}

.splide__arrow--prev {
    left: -34px !important;
}

.splide-arrow--next {
    right: 0;
}

.splide__arrow--next {
    right: -34px;
}

.span-date {
    font-size: 12px;
}

.splide__track--draggable {

    background-color: #f8f8f8 !important;
    border-radius: 12px;
}

.trending-empty-box {
    /* border: 2px solid dodgerblue; */
    /* padding: 25px 0px; */
    /* box-shadow: inset 0 -3em 3em rgb(255, 255, 255),
        0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3); */
    /* border-radius: 15px; */




    /* box-shadow: inset 0 -3em 5em rgb(255, 255, 255), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    background-color: salmon; */
    border: 1px solid dodgerblue;
    padding: 20px 0px;
    box-shadow: inset 0 -3em -1em rgb(255, 255, 255), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    box-shadow: inset 0 -5em -6em rgb(255, 255, 255), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    background-color: #d2e8ff;

}

@media only screen and (min-width:0px)and (max-width:908px) {
    .product-carousel-container {
        position: relative;
        left: 35px;
        width: 80%;

    }

    .text-container {
        /* width: 200px !important;

        overflow: hidden;

        white-space: nowrap;

        text-overflow: ellipsis;

        padding: 5px; */
        word-wrap: break-word;

    }


}

.truncate-slide {

    /* width: 500px;
   
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.span-like {
    font-size: 11px;
}

.text-container {
    /* width: 500px;

    overflow: hidden;

    white-space: nowrap;

    text-overflow: ellipsis;

    padding: 5px; */
    word-wrap: break-word;

}

.image-published {
    display: flex;
    justify-content: center;
}

.tweet-span {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two equal-width columns */
    grid-gap: 10px;
    /* Adjust the gap between columns as needed */
    list-style: none;
    margin-bottom: 10px;
    /* Remove default list styles */

}



/* -----------MY QUEUE CSS ENDS----------------- */









/* -----------MY SCHEDULE QUEUE CSS Starts----------------- */
/* .queue-schedule-list {
    background-color: white;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    padding: 20px;
}

.image-myqueue {
    display: flex;
    justify-content: center;
}

.queue-schdule-image {
    width: 100px;
    height: 100px
}







.image-myqueue {
    display: flex;
    flex-wrap: wrap;
}

.image-container {
    flex-basis: 50%;
    max-width: 50%;
    box-sizing: border-box;
    padding: 5px;
} */

/* -----------MY SCHEDULE QUEUE CSS ENDS----------------- */








/* ------------------------------------MY SETTINGS CSS STARTS---------------------------- */

.setting-header-box h4 {
    font-weight: 600;
    color: black;
}


.setting-2nd-box {
    margin-top: 30px;
}

.setting-2nd-box h5 {
    font-weight: 400;
    font-size: 20px;
    color: black;


}

.setting-form-box {
    /* margin-top: 20px; */
}

.email-input {
    width: 100%;
    border: none;
    padding: 10px 0px 10px 10px;
    background-color: #e4e4e4;
    border-radius: 10px;
    margin-right: 10px;

}

.button-save {
    background-color: #284E79;
    color: white;
    border: none;
    padding: 10px 30px 10px 30px;
    border-radius: 10px;

}


.Ai-Screen-box {
    margin-top: 70px;
}

.Ai-Screen-box h5 {
    font-weight: 500;
    color: black;
    font-size: 20px;
}

.slider-ai {
    /* width: 80%;
    display: flex; */
}

.Delete-box {}

.Delete-box h5 {

    font-weight: 500;
    font-size: 20px;
    color: black;
}

.delete-box-contenet {
    margin-bottom: 30px;
}

.button-dele {
    border: none;
    color: #284E79;
    background-color: white;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    font-weight: 600;
    border: 2px solid #284E79;
}

.setting-select {
    color: black;
    border: none;
    padding: 10px 0px 10px 10px;
    background-color: #e4e4e4;
    border-radius: 10px;
    margin-right: 10px;
    width: 100%;

}

.form-display-data-setting {

    height: auto;
    margin-bottom: 30px;
    margin-top: 30px;
}

.slider-text p {
    font-size: 14px;
    color: rgb(93, 92, 92);
    font-weight: bold;
}

.AI-download-receipt {

    margin-top: 30px;
    margin-bottom: 30px;
}

.AI-download-receipt-2 {

    margin-top: 30px;
    margin-bottom: 30px;
}

.AI-download-receipt h5 {
    color: black;
    font-size: 20px;
    font-weight: 500;
}

.donwload-re {
    background-color: white;
    color: #284E79;

    font-weight: 600;
    border: 2px solid #284E79;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    margin-top: 10px;
}

.payment-plan-box {
    background-color: white;
    padding: 20px;
    margin-bottom: 50px;
    margin-top: 30px;
    box-shadow:
        inset 0 -3em 3em rgb(255, 255, 255),
        0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 10px;

}

.currentplan {
    display: flex;
    justify-content: space-between;
}

.current-plan-text h5 {
    font-size: 13px;
}

.current-plan-text h4 {
    margin-bottom: 10px;
}

/* .donwload-re:hover {
    background-color: white;
    color: #ff9100;
    border: 1px solid #ff9100;
} */

.upgrade-subs {
    float: right;
}

.select-interest {
    background-color: "#eeeeee";
    border: "1px solid #ced4da";
    border-radius: "10px";
    min-height: "35px";
    height: "40px";
    width: "100%";
    margin-bottom: "15px";
}

.slider-settings {
    color: #284E79 !important;
}



@media only screen and (max-width: 2900px) and (min-width: 901px) {
    .cancel-subs {
        text-align: center;

    }

    .upgrade-subs h4 {
        text-align: center;
    }


}


@media only screen and (max-width: 900px) and (min-width: 0px) {
    .button-save {
        margin-top: 10px;
        padding: 7px 20px 7px 20px;

    }

    .current-plan-text {
        margin-bottom: 20px;
    }

    .delete-box {
        margin-top: 50px;
    }

    .cancel-subs {
        /* float: right !important; */
        /* margin-top: 20px; */
    }

    .upgrade-subs {
        /* margin-top: 20px; */
    }





}

.delete-account-delete {
    background-color: rgb(40, 78, 121);
    border: none;
    color: white;
    padding: 6px 16px;
    border-radius: 5px;
}


/* ------------------------------------MY SETTINGS CSS ENDS---------------------------- */


/* ------------------------------------MY SEARCH CSS STARTS---------------------------- */

.search-tweet h5 {
    font-weight: 600;
    font-size: 20px;
    color: black;
}

.search-box-conatiner {
    position: relative;
    margin-top: 50px;
}

.search-box-conatiner h3 {
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
    font-family: 'Roboto';
    text-align: center;
}

.search-box-conatiner p {

    text-align: center;
}


/* .search-input {
    border-radius: 30px;
    background-color: #7FBDFF;
    text-align: center;
    align-items: center;
    width: 50%;
} */

.search-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
    background-color: #284E79;
    width: 50%;
    margin: 0 auto;
    padding: 8px 0px;

}

.search-input-text::placeholder {
    color: rgb(244, 242, 242);
}

.search-input input[type="text"] {
    margin: 0 10px;

}


.search-input-text {
    border: none;
    background: transparent;
    width: 80%;
    color: white;
}

.search-icon {
    color: white;
    font-size: 20px;
}

.filter-dropdown {
    position: relative;
    display: inline-block;
}

.filter-icon {
    cursor: pointer;
}

.filter-options {
    position: absolute;
    top: 30px;
    right: 0;
    width: 50%;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 35px 25px 15px 25px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.filter-options label {
    display: block;
    margin-bottom: 8px;
}

.filter-options input[type='checkbox'] {
    margin-right: 4px;
}

.searchtab-notext {
    margin-top: 20px;
}

.searchtab-notext h5 {
    font-size: 23px;
}

.display-maximum-slider {
    display: flex;
    justify-content: space-between;
}

.display-maximum-slider span {
    color: #284E79;
}

.slider-filter {
    margin-top: -10px;
    color: #284E79 !important;

}

.checkbox-filter {
    text-align: left;
}

.form-check-input:checked {
    border-color: #284E79;
    background-color: #284E79;
}

.button-filter {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.button-reset {
    background-color: white;
    color: #284E79;
    border-radius: 10px;
    border: 1px solid #284E79;
    padding: 5px 13px 5px 13px;
}

.button-submit {
    background-color: #284E79;
    border-color: #284E79;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 5px 13px 5px 13px;
}

.filter-cancel {
    position: relative;
    right: -16px;
    top: -31px;
    float: right;
}

.search-tips-serachtabs {
    margin-top: 10px;
    position: relative;
    left: -12rem;
    cursor: pointer;
}

.search-tips-serachtabs-ul ul {
    list-style-type: none;
    position: relative;
    left: 29rem;

}

@media only screen and (max-width: 900px) and (min-width: 0px) {

    .filter-options {
        position: absolute !important;
        top: -20% !important;
        right: 0% !important;
        width: 100% !important;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 35px 25px 15px 25px;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 1;

    }

    .search-input {
        width: 100%;
    }

    .search-tips-serachtabs {
        margin-top: 10px;
        position: relative;
        left: -9rem;
        cursor: pointer;
    }

    .search-tips-serachtabs-ul ul {
        list-style-type: none;
        position: relative;
        left: 9rem;

    }



}

@media only screen and (max-width: 1020px) and (min-width: 760px) {



    .search-tips-serachtabs {
        margin-top: 10px;
        position: relative;
        left: -19rem;
        cursor: pointer;
    }

    .search-tips-serachtabs-ul ul {
        list-style-type: none;
        position: relative;
        left: 19rem;

    }



}

/* ------------------------------------MY SEARCH CSS ENDS---------------------------- */





/* ------------------------------------MY DAILY INSPIRATION CSS STARTS---------------------------- */
.DailyIns-card {
    border: 1px solid rgb(200, 197, 197) !important;
    /* border-top-right-radius: 40px;
    border-bottom-left-radius: 40px; */
    border-radius: 15px;
}

.DailyIns-card-header {
    display: flex;
    justify-content: space-between;
}

.DailyIns-card-image {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    position: relative;
    top: -6px
}

.DailyIns-img-fluid {
    border-radius: 50px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.DailyIns-Card-footer {
    border-top: 1px solid rgb(225, 225, 225);
    padding: 0 !important;
}

.faQuoteLeft {
    float: left;
}

.faQuoteRight {
    float: right;
    position: relative;
    top: -16px
}

.quote {
    margin-top: 20px;
}


.dailyIns-span span {
    color: lightgrey;
    font-size: 13px;
    margin-right: 10px;
}

.dailyIns-span button {
    background-color: rgb(238, 238, 238);
    color: black;
    border: none;
    border-radius: 3px;
    margin-right: 5px;

}


.DailyIns-Card-footer-right button {
    border: none;
    border: 1px solid #e4e4e4;
    background-color: white;
    border-radius: 5px;
    margin-right: 4px;
    padding: 3px 15px 3px 15px;
    font-size: 12px;
    background-color: #284E79;
    color: white;
    border: none;
}

.DailyIns-Card-footer-right-icon {
    color: #7FBDFF;
}

.footer-card-list {
    list-style-type: none;
    position: relative;
    right: 40px;
    margin-top: 10px;
}

.footer-card-list li {
    display: inline-block;
    margin-left: 20px;

}

.card-title {
    font-weight: 600;
    color: black;

}




.dail-masornry {
    /* background-color: #ff9100; */
    padding: 0px 0px 0px 0px;
    margin-top: 30px;
}

@media only screen and (min-width:0px)and (max-width:908px) {
    .dail-masornry {

        padding: 0px 0px 0px 20px;
        margin-top: 30px;
    }
}

.my-masonry-grid {
    display: flex;
    /* margin-left: -30px; */

    width: 100%;
}

.my-masonry-grid_column {
    padding-left: 10px;

    background-clip: padding-box;
}

@media (max-width: 1200px) {
    .my-masonry-grid {
        margin-left: -20px;

    }

    .my-masonry-grid_column {
        padding-left: 20px;

    }
}


.custom-tooltip {
    background-color: blue;
    color: white;
    padding: 8px;
    border-radius: 4px;
}

.DailyIns-popover-header {
    display: flex;
}

.popver-paragrapgh {
    padding-left: 10px;
}

.popver-paragrapgh span {
    color: gray;
}


.popover-header-dailyins {
    /* background-color: rgb(224, 232, 255); */
    background-color: white;
    border: none;
}

.popover-img-fluid {
    border-radius: 50px;
    width: 40px;
    height: 40px;
}

.popover-header-dailyins h6 {
    font-size: 15px;
}

.popover-header-dailyins span {
    font-size: 13px;
}

.popover-followers {
    border-top: 1px solid black;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px 0px 0px 0px;
}

.popover-followers span {
    font-size: 14px;
}

.popver-link {
    color: #7FBDFF;
}

.popover-body-dailyIns {
    padding-top: 0px !important;
}

.daily-image-card {
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
}

.icon-text {
    color: dodgerblue;
    /* font-size: 10px; */
}

.daily-notext {
    /* background-color: #f8f8f8; */
    /* box-shadow: inset 0 -3em 3em rgb(255, 255, 255),
        0 0 0 2px rgb(255, 255, 255),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 25px 0px;
    border: 2px solid #197fec; */

    border: 1px solid dodgerblue;
    padding: 20px 0px;
    box-shadow: inset 0 -3em -1em rgb(255, 255, 255), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    box-shadow: inset 0 -5em -6em rgb(255, 255, 255), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    background-color: #d2e8ff;

}

.daily-notext h4 {
    font-size: 23px;
    font-family: 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
}

.over-drop {
    background-color: white;
    z-index: 1000;
    position: absolute;
    right: 0;
    top: 2.5rem;
    border: 1px solid grey;
    border-radius: 10px;
    /* padding-top: 10px; */
}

.over-drop ul {
    list-style-type: none;
}

.over-drop li {
    /* position: relative;
    left: 10px; */
    list-style-type: none;
    padding-left: 10px;
    padding-right: 10px;
    /* border-bottom: 1px solid grey; */
    padding-top: 7px;
    padding-bottom: 7px;
    overflow-x: hidden;
    font-size: 12px;
    cursor: pointer;
}

.popover-dail {
    position: absolute;
    /* top: 30px;
    left: 0; */
    top: -29px;
    left: 43px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 999;
    width: 270px;
}

.popover-noti {
    position: absolute;
    /* top: 30px;
    left: 0; */
    top: -11px;
    left: 43px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 999;
    width: 270px;
    border-radius: 10px;
}

.popover-noti span {
    font-size: 13px;
}

.popover-dail span {
    font-size: 13px;
    position: relative;
    top: -5px;
}

.popover-dail p {
    font-size: 13px;
}

/* ------------------------------------MY DAILY INSPIRATION CSS ENDS---------------------------- */




/* ------------------------------------MY AI GENERATED CSS STARTS---------------------------- */
.search-icon-AI {
    /* cursor: pointer;
    color: white;
    font-size: 20px;
    margin-right: 20px; */

    cursor: pointer;
    color: white;
    font-size: 20px;
    margin-right: 0px;
    margin-left: 23px;

}

.search-tweet-ai h4 {
    font-weight: 600;
    color: black;
}

.search-input-ai {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: #284E79;
    width: 50%;
    margin: 0 auto;
    padding: 8px 0px;
    margin-top: 20px;

}

.search-input-text-ai::placeholder {
    color: rgb(240, 240, 240) !important;
}


.search-tweet h4 {
    color: black;
}

.aiborder {

    color: black;

}

.search-input-ai input[type="text"] {
    margin: 0 10px;

}

.search-input-text-ai {
    border: none;
    background: transparent;
    width: 100%;
    color: white;
}

.AI-Card-footer-right button {

    border: 1px solid #e4e4e4;
    background-color: #284E79;
    border-radius: 5px;
    margin-right: 13px;
    color: white;
    border-radius: 5px;
    /* color: #197fec;
    background-color: transparent;
    border: none;
    font-size: 22px; */
}

.search-input-text-ai::placeholder {
    color: rgb(217, 216, 216);
    /* Set the color you want for the placeholder */
}




/* ------------------------------------MY AI GENERATED CSS ENDS---------------------------- */


.link-preview {
    margin: auto;
    display: block;
    font-weight: normal;
    text-decoration: none;
    max-width: 500px;
}

.link-preview+.link-preview {
    margin-top: 1rem;
}
.iq-right-fixed {
	margin: 0 0.938rem;
}

.right-sidebar-mini {
	top: -0.5rem;
	z-index: 100;
	position: fixed;
	width: 24.25rem;
	right: 0;
	z-index: 1;
	transform: translateX(calc(111% + -2em));
	box-shadow: inset 0 -3em 3em rgb(255, 255, 255),
		0 0 0 2px rgb(255, 255, 255),
		0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

	@if $enable-transitions ==true {
		@include transition-mixin(all 0.3s ease-in-out);
	}

	.side-left-icon {
		display: none;
	}
}

@media only screen and (max-width: 900px) and (min-width: 0px) {
	.right-sidebar-mini {
		top: -0.5rem;
		z-index: 100;
		position: fixed;
		width: 22.25rem;
		right: 0;
		z-index: 1;
		transform: translateX(calc(111% + -2em));

		@if $enable-transitions ==true {
			@include transition-mixin(all 0.3s ease-in-out);
		}

		.side-left-icon {
			display: none;
		}
	}


}

.right-sidebar-toggle {
	position: absolute;
	margin-left: -2.5rem;
	background: $white;
	padding: 0.5rem;
	display: inline;
	top: 0;
	z-index: 99;

	@if $enable-rounded ==true {
		@include radius-mixin(30px 0px 0px 30px);
	}

	@if $enable-shadows ==true {
		@include shadow-mixin($box-shadow);
	}

	cursor: pointer;
}

.right-sidebar-panel {
	background-color: $white;

	@if $enable-shadows ==true {
		@include shadow-mixin($box-shadow);
	}

	height: 100vh;
	padding: 0.938rem;

	.media-height {
		max-height: 98vh;
		overflow-y: scroll;
	}
}

.right-sidebar-mini-dark {
	background-color: red;

	@if $enable-shadows ==true {
		@include shadow-mixin($box-shadow);
	}

	height: 100vh;
	padding: 0.938rem;

	.media-height {
		max-height: 98vh;
		overflow-y: scroll;
	}
}

.right-sidebar {
	transform: translateX(calc(10% + -1em));

	.side-right-icon {
		display: block;
	}

	.side-left-icon {
		display: none;
	}

	&.right-sidebar-mini {
		.side-right-icon {
			display: none;
		}

		.side-left-icon {
			display: block;
		}
	}
}

.offcanvas.live-customizer {
	z-index: 1111;

}

@media (min-width:1200px) {
	body.sidebar-main {
		.iq-sidebar {
			width: 5rem;

			.iq-sidebar-menu {
				.iq-menu {
					li {
						a {
							span {
								opacity: 0;
								display: none;

								@if $enable-transitions ==true {
									@include transition-mixin(all 0.3s ease-in-out);
								}
							}
						}
					}
				}
			}
		}

		.iq-sidebar-logo {
			a {
				span {
					opacity: 0;
					display: none;

					@if $enable-transitions ==true {
						@include transition-mixin(all 0.3s ease-in-out);
					}
				}
			}
		}

		.iq-sidebar-menu {
			.iq-menu {
				li {
					a {
						.badge {
							opacity: 0;
							display: none;

							@if $enable-transitions ==true {
								@include transition-mixin(all 0.3s ease-in-out);
							}
						}
					}
				}
			}
		}

		.iq-footer {
			margin-left: 5rem;
			width: calc(100vw - 20.5rem);
		}

		&.right-sidebar-close {
			.iq-footer {
				width: calc(100vw - 5.5rem);
			}
		}
	}

	body {
		&.right-sidebar-close {
			.iq-footer {
				width: calc(100vw - 5.5rem);
			}
		}

		&:not(.sidebar-main) {
			&.right-sidebar-close {
				.iq-footer {
					width: calc(100vw - 16.1rem);
				}
			}
		}
	}

	.sidebar-main {
		.iq-sidebar {
			.iq-submenu {
				li {
					a {
						font-size: 0;
					}
				}
			}
		}
	}

	.right-sidebar-mini {
		transform: translateX(calc(10% + -1em));

		&:hover {
			.right-sidebar-toggle {
				opacity: 1;
				margin-left: -2.5rem;

				@if $enable-transitions ==true {
					@include transition-mixin(all 0.3s ease-in-out);
				}
			}
		}
	}

	.right-sidebar-toggle {
		z-index: -1;
		opacity: 0;
		margin-left: 0;

		@if $enable-transitions ==true {
			@include transition-mixin(all 0.3s ease-in-out);
		}
	}

	.right-sidebar-mini.right-sidebar {
		.right-sidebar-toggle {
			margin-left: -3.5rem;
			opacity: 1;
		}

		transform: translateX(calc(111% + -2em));
	}
}

@media (min-width:1300px) and (max-width:1499px) {
	.right-sidebar-mini {

		// transform: translateX(calc(111% + -8em));
		.media-body {
			@include opacity-mixin(0);
		}
	}

	.right-sidebar-mini.right-sidebar {
		transform: translateX(calc(10% + -1em));

		.media-body {
			@include opacity-mixin(0);
		}
	}

}

@include media-breakpoint-down(lg) {
	.right-sidebar-toggle {
		position: absolute;
		top: 50%;
	}
}